.App {
  text-align: center;
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0;
  padding: 0 50px 50px;
  background-color: #121212;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.App-logo {
  height: 60px;
  pointer-events: none;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #121212;
  color: #ffffff;
}

.header-text {
  opacity: 0.5;
}

.kiboshib-link {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #ff9800;
  border-radius: 5px;
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s;
}

.kiboshib-link:hover {
  background-color: rgba(255, 152, 0, 0.1);
}

.kiboshib-logo {
  margin-left: 10px;
  height: 24px;
}

@media (max-width: 767px) {
  .header-text {
    display: none;
  }

  .kiboshib-text {
    display: none;
  }

  .kiboshib-link {
    padding: 8px;
  }
}

.image-container {
  width: 48%;
  margin-right: 2%;
  position: relative;
  overflow: hidden;
  border: 1px solid #333;
  border-radius: 10px;
  background-color: #2a2a2a;
}

@media (max-width: 768px) {
  .image-container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    aspect-ratio: 1 / 1;
  }
}

.meme {
  position: relative;
  width: 100%;
  height: 100%;
}

.meme img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.top-text, .bottom-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-family: 'Impact', sans-serif;
  color: white;
  text-align: center;
  width: 90%;
  text-transform: uppercase;
  word-wrap: break-word;
  max-height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.8);
}

.top-text {
  top: -20px;
}

.bottom-text {
  bottom: -20px;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #2a2a2a;
  color: #999;
  position: relative;
}

.controls {
  width: 50%;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  color: #ffffff;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

@media (max-width: 768px) {
  .controls {
    width: 100%;
    max-height: none;
  }
  
  .today-limit {
    position: static;
    margin-bottom: 15px;
    display: inline-block;
  }
  
  .controls > *:first-child {
    margin-top: 0;
  }
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #ffffff;
}

.input-group input {
  width: 100%;
  padding: 10px 8px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #333;
  color: #ffffff;
}

.generate-btn, .generate-captions-btn {
  width: calc(50% - 5px);
  padding: 12px 10px;
  font-size: 16px;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: background-color 0.3s;
  box-sizing: border-box;
  position: relative;
}

.generate-btn {
  background-color: #ff9800;
  margin-right: 5px;
}

.generate-captions-btn {
  background-color: #2196f3;
  margin-left: 5px;
}

.generate-btn:hover, .generate-captions-btn:hover {
  filter: brightness(1.1);
}

.generate-btn:disabled, .generate-captions-btn:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.counter {
  font-size: 14px;
  color: #aaa;
  position: absolute;
  top: 20px;
  right: 20px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 10;
}

.generated-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 769px) {
  body {
    overflow: hidden;
  }
  
  .App {
    height: 100vh;
    overflow: hidden;
  }

  .controls {
    overflow-y: hidden;
  }
}

.recent-images {
  margin-top: 20px;
  text-align: left;
}

.recent-images h3 {
  margin-bottom: 10px;
  color: #ffffff;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.recent-image-container {
  width: calc(25% - 7.5px);
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  background-color: #333;
}

.recent-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recent-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444;
  color: #999;
}

.recent-image-caption {
  display: none;
}

.download-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.download-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.download-btn svg {
  width: 24px;
  height: 24px;
  color: white;
}

.recent-download-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.recent-download-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.recent-download-btn svg {
  width: 18px;
  height: 18px;
  color: white;
}

.button-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  z-index: 5;
}

.style-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.style-button {
  padding: 8px 12px;
  font-size: 14px;
  color: white;
  background-color: #4a4a4a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.style-button:hover {
  background-color: #5a5a5a;
}

.style-button.active {
  background-color: #ff9800;
}

.today-limit {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff9800;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  z-index: 100;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-wrapper input {
  flex-grow: 1;
  padding-right: 30px;
}

.clear-input {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #999;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
}

.clear-input:hover {
  color: #fff;
}

.caption-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loader-text {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 767px) {
  header div:first-child {
    display: none;
  }

  header a span {
    display: none;
  }
}

.App-main {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
}

.image-container, .controls {
  flex: 1;
  height: 100%;
}

@media (max-width: 767px) {
  .App-main {
    flex-direction: column;
    height: auto;
  }
}

.controls > *:first-child {
  margin-top: 40px;
}
